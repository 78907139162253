<template>
  <div class="bank-info">
    <table class="table-odded">
      <tr><td>Наименование</td><td>{{info.name}}</td></tr>
      <tr><td>Полное наименование</td><td>{{info.fullname}}</td></tr>
      <tr><td>БИК</td><td>{{info.bik}}</td></tr>
      <tr><td>SWIFT</td><td>{{info.swift}}</td></tr>
      <tr><td>Кор. счет</td><td>{{info.csaccount}}</td></tr>
      <tr><td>ИНН/КПП</td><td>{{info.inn + ' / ' + info.kpp}}</td></tr>
      <tr><td>Адрес</td><td>{{info.address}}</td></tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "BankInfo.vue",
  props: {
    info: {
      type: Object
    },
  },
}
</script>

<style scoped>
.bank-info{
  width: 85%;
  margin: auto;

}
.table-odded{
  border-collapse: collapse
}
.table-odded tr{
  line-height: 30px;
}
.table-odded tr:nth-child(odd){
  background-color: #e5f0f7;
}
.table-odded td{
  margin-left: 0.4%;
}
</style>
