<script>
import ProfileFIleCard from "../ProfileFIleCard";
import CompanyRequisite from "./CompanyRequisite"

export default {
  name: "CompanyContractCard",
  
  components: {
    ProfileFIleCard,
    CompanyRequisite
  },
  
  props: {
    contract: {
      type: [Array, Object]
    }
  }
}
</script>

<template>
<div class="contract">
  <div v-if="contract != null">
    <CompanyRequisite :is-company-info="false" class="contract__requisite" :payersData="contract"/>
    <div
      v-if="contract.files[0]"
      class="contract__container-list"
    >
      <div
        class="contract__container-list-files"
        v-for="(file, index) in contract.files"
        :key="index"
      >
        <div
          class="contract__container-list-files-card"
          v-if="file != null"
        >
          <ProfileFIleCard :file="file"/>
        </div>
      </div>
      
      <v-btn
        class="contract__container__add-btn"
        depressed
        icon
        color="var(--main-bg-color)"
        @click="$emit('showFileForm')"
      >
        <v-icon class="mr-1">
          mdi-upload
        </v-icon>
      </v-btn>
    </div>
  </div>
  
  <v-btn
    v-if="!contract.files[0]"
    class="contract__container__add-btn-new"
    depressed
    icon
    color="var(--main-bg-color)"
    @click="$emit('showFileForm')"
  >
    <template>
      <v-icon>
        mdi-upload
      </v-icon>
      <br>
      <div class="mr-2">добавить</div>
    </template>
  </v-btn>
</div>
</template>

<style lang="scss" scoped>
.contract {
  
  &__requisite {
    margin: 5px;
  }
  
  &__container {
    padding: 10px 20px 30px;
    display: grid;
    
    &-list {
      position: relative;
      height: auto;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      
      &-files {
        text-align: center;
        margin: 0 2px;
        display: flex;
        float: left;
        border: none;
        width: auto;
        position: relative;
        
        &-card {
          position: relative;
          display: block;
          //grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          //display: flex;
          height: 130px;
        }
      }
    }
    
    &__add-btn {
      margin: 10px 50px 5px 20px;
      text-transform: none;
      letter-spacing: 0.02em;
      font-size: 14px;
      color: var(--bg-dark);
      font-weight: 700;
      width: 52px;
      height: 57px;
      border: 1px dashed rgba(67, 98, 114, 0.5);
      border-radius: 8px;
      align-items: center;
      
    }
    
    &__add-btn-new {
      margin: 0 15px 0 5px;
      border: 1px dashed rgba(67, 98, 114, 0.5);
      border-radius: 10px;
      color: var(--bg-dark);
      height: 90px;
      width: 100px;
      font-size: 12px;
      line-height: 14px;
      display: flex;
      font-weight: 800;
      flex-direction: column !important;
      align-items: center;
      letter-spacing: 0.00892857em;
      text-transform: lowercase;
    }
  }
}
</style>
