<template>
  <div class="mt-editor-form">
    <div class="mt-order-fixed-block">
      <div class="mt-order-close-wdw">
        <v-btn :class="getsendbuttonclass()" color="primary" text @click="sendComment">Отправить</v-btn>
        <v-btn class="close-btn" color="primary" text @click="$emit('close', 0)">Закрыть</v-btn>
      </div>
      <v-text-field
        list="uusers"
        :label="geteditlabel()"
        :value="uuser"
        clear-icon="mdi-close-circle"
        v-on:input="recipientchange"
        v-if="recipientshow"
      ></v-text-field>
      <datalist id="uusers">
        <option v-for="item in uusers_reference" :value="item.name" :key="item.id"/>
      </datalist>
      <v-textarea
        name="input-7-1"
        rows="18"
        row-height="15"
        label="Текст сообщения"
        v-model="description"
      ></v-textarea>
   </div>
  </div>
</template>

<script>
import {bus} from "../../../router/bus";
export default {
  name: "MessageForm.vue",
  props: {
    document: {
      type: Object
    },
    is_new: {
      type: Boolean
    },
    ord_id:{
      type: Number
    },
    commentType: {
      type: String,
      required: false,
      default: () => "client",
      validator: (value) => ["client", "dcl"].includes(value),
    }
  },
  data() {
    return {
      uuser: '',
      description: '',
      uusers_reference: [],
      recipientshow: false,
    }
  },
  mounted() {
     setTimeout(this.setVizited, 5000);
       if (!this.is_new) {
         this.uuser = this.document.uuser
         this.description = this.document.full_text
         this.recipientshow = true
       } else {
         this.getuserreference()
         this.recipientshow = false
       }
  },
  methods:{
    geteditlabel(){
      return (this.is_new) ? "Кому" : "От";
    },
    getsendbuttonclass(){
      return (this.is_new) ? "" : "btn-hidden";
    },

    sendComment() {
     if (this.commentType === "dcl") {
       this.sendDcl()
     } else {
       this.sendClient()
     }
    },

    sendDcl() {
      this.$postapi(this.$address + this.$setters.setClientCustomsRequestComment.uri, {
        method: this.$setters.setClientCustomsRequestComment.name,
        obj_id: this.ord_id,
        uuser_id: this.$_getsetting('user_id'),
        uuser: this.$_getsetting('fio'),
        description: this.description
      }).then(data => {
        if (data.error == 0) {
          this.$emit('close', 0)
          bus.$emit("recalccommentscount")
  
          bus.$emit("show_notify", {
            color: "success",
            notifytext: data.message
          });
          
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        }
      });
    },

    sendClient() {
      this.$postapi(this.$address + this.$setters.setClientRequestComment.uri, {
        method: this.$setters.setClientRequestComment.name,
        obj_id: this.ord_id,
        uuser_id: this.$_getsetting('user_id'),
        uuser: this.$_getsetting('fio'),
        description: this.description
      }).then(data => {
        if (data.error == 0) {
          this.$emit('close', 0)
  
          bus.$emit("recalccommentscount")
  
          bus.$emit("show_notify", {
            color: "success",
            notifytext: data.message
          });
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        }
      });
    },

    getuserreference(){
      this.$postapi(this.$address + this.$getters.getReferenceData.uri, {
        method: this.$getters.getReferenceData.name,
        reference_name: 'gorgona_prc_users_main_outsorcing',
      }).then(data => {
        if (data.error == 0) {
            this.uusers_reference = JSON.parse(data.message)
        } else {
            bus.$emit("show_notify", {
                color: "error",
                notifytext: data.message
            });
        }
      });
    },
  }
}
</script>

<style scoped>
.mt-editor-form{
    background-color: #fff;
    margin-bottom: 0.4%;
    overflow: hidden;
}

.mt-order-close-wdw{
    text-align: right;
    margin: 0.4%;
}

.mt-order-fixed-block{
    width: 98%;
    margin: auto;
    min-height: 33.8541vw;
}

.close-btn{
    min-height: 2.5vw;
}

.btn-hidden{
    display: none;
}

</style>
