<script>
import FinanceInvoicesList from "./FinanceInvoicesList.vue";
import FinanceDocumentRequestForm from "./FinanceDocumentRequestForm.vue";
import FinanceDocumentList from "./FinanceDocumentList.vue";

export default {
  name: "NotPayedInvoicesTab",
  
  components: {
    FinanceInvoicesList,
    FinanceDocumentRequestForm,
    FinanceDocumentList
  },
  
  data() {
    return {
      showDocumentRequestForm: false,
    }
  },
  
  mounted() {
  },
  
  methods: {
  }
}
</script>

<template>
  <div class="finance">
    <v-tabs
      class="finance__container"
      color="var(--bg-dark)"
      hide-slider
    >
      <v-tab class="finance__tab" active-class="finance__tab-active">Реестр</v-tab>
      <v-tab class="finance__tab" active-class="finance__tab-active">Запросы</v-tab>
  
      <v-tab-item style="margin-top: -25px;">
        <FinanceInvoicesList/>
      </v-tab-item>
      
      <v-tab-item>
        <v-btn
          class="finance__req-btn"
          color="#EF8159"
          outlined
          @click="showDocumentRequestForm = true"
        >
          Заказать документ
        </v-btn>
        
        <v-dialog
          v-model="showDocumentRequestForm"
          width="40%"
          height="60%"
          content-class="finance__request"
          transition="dialog-bottom-transition"
          @click:outside="showDocumentRequestForm = false"
        >
          <FinanceDocumentRequestForm
            @close="showDocumentRequestForm = false"
            v-if="showDocumentRequestForm == true"
            class="finance__request-form"
          />
        </v-dialog>
        
        <FinanceDocumentList class="finance__request-list" />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<style scoped lang="scss">
.finance {
  height: 85vh;
  display: flex;
  background-color: #fff;
  overflow-x: auto !important;
  width: 100%;
  
  &__container {
    margin-top: 15px;
    background: none !important;
  }
  
  &__tab {
    font-weight: 700;
    height: 44px;
    max-width: 236px;
    font-size: 14px;
    line-height: 20px;
    border-radius: 8px;
    text-transform: none;
    letter-spacing: 0.5px;
    padding: 8px 25px;
    left: 12px;
    border: 1px solid rgba(255, 255, 255, 0.4);
  }
  
  &__tab-active {
    background: var(--main-bg-color);
    color: white;
    height: 40px !important;
    border-radius: 4px;
  }
  
  &__req-btn {
    top: 0px;
    left: calc(100% - 340px);
    display: flex;
    z-index: 10;
  }
  
  &__request-form {
    width: 100%;
    height: 110%;
  }
  
  &__request-list {
    margin-top: -50px !important;
  }
}

</style>
