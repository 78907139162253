<script>
import FilesCard from "@/components/request-form/side-form-tabs/FilesCard";

export default {
  name: "FilesTab",
  
  components: {
    FilesCard,
  },
  
  props: {
    files: {
      type: Array
    }
  },
  
  data() {
    return {
    }
  },
  
  methods: {
    fileUploaded(value) {
      this.fileName = value
    },
  },
}
</script>

<template>
  <div class="mt-order-main-files-container">
    <div class="file-input-container">
      <v-btn
        class="file-input"
        depressed
        icon
        color="var(--main-bg-color)"
        @click="$emit('showFileForm')"
      >
        <v-icon class="mr-1">
          mdi-upload
        </v-icon>
      </v-btn>
      загрузить файл
    </div>
    <div class="file-card-container" v-bind:key="name" v-for="(item, name) in files">
        <FilesCard :file ="item"/>
    </div>
  </div>
</template>

<style scoped>
.mt-order-main-files-container {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  text-align: center;
  float: left;
  border: none;
  max-height: 80vh;
  overflow-y: scroll;
}

.file-input-container {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  /*color: var(--main-bg-color);*/
  color: var(--bg-dark);
}

.file-input {
  margin: 10px auto 7px;
  text-transform: none;
  letter-spacing: 0.02em;
  font-size: 14px;
  color: var(--bg-dark);
  font-weight: 700;
  width: 52px;
  height: 57px;
  border: 1px dashed rgba(67, 98, 114, 0.5);
  border-radius: 8px;
  align-items: center;
}

.file-card-container {
  float: left;
  margin: 0 auto;
  width: 9.375vw;
  max-height: 6.25vw;
  overflow: hidden;
  list-style-type: none;
}
</style>
