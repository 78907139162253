<script>
export default {
  name: "ModalNotification",
  props: {
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
  },
}
</script>
<template>
  <v-card class="notification">
    <div class="notification__ttl">
      {{title}}
    </div>
    <v-card-actions>
      <v-spacer></v-spacer>
        <v-btn
          color="var(--main-bg-color)"
          text
          @click="$emit('close')"
        >
          Закрыть
        </v-btn>
      </v-card-actions>
  </v-card>
</template>
<style scoped lang="scss">
.notification {
  padding: 20px;
  
  &__ttl {
    font-family: "Manrope", sans-serif;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-size: 22px;
    padding-left: 20px;
  }
}
</style>
