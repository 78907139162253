<template>
  <div class="info-block">
    <div class="info-block_title">Добро пожаловать в Vedexx!</div>
    <div class="info-block_text">Мы отправили вам письмо на указанную электронную почту.
      <br/>Для подтверждения регистрации и активации учётной записи, перейдите по ссылке из письма. </div>
  </div>
</template>

<script>

export default {
  name: "RegistrationDone",
}
</script>

<style scoped lang="scss">
.info-block {
  width: 100%;
  position: relative;
  font-family: 'Manrope', sans-serif;
  margin: 20vh auto;
  text-align: center;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  
  @media (max-width: $tablet-width) {
    margin: 40px auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.info-block_title {
  font-weight: 800;
  font-size: 40px;
  line-height: 60px;
}

.info-block_text {
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
}
</style>
