<template>
  <div class="info-block">
    <div class="info-block_title">Ваша регистрация прошла успешно!</div>
    <div class="info-block_text">
      Вы зарегистрировались в Vedexx.com. <br/>
      Ваши логин и пароль для входа отправлены на электронную почту.
      <br/>
    </div>
  </div>
</template>

<script>
import {bus} from "../../router/bus";

export default {
  name: "SelfRegistrationMessage",
  data: () => ({
    key : "",
  }),
  created() {
    this.registerbykey(this.$route.query.key)
  },
  methods:{
    registerbykey(_key){
      this.$postapi(this.$address + this.$getters.registerByKey.uri, {
        method: this.$getters.registerByKey.name,
        key: _key
      }).then(data => {
        if (data.error == 0){
          bus.$emit("show_notify", {
            color: "success",
            notifytext: data.message
          });
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
.info-block {
  width: 100%;
  position: relative;
  font-family: 'Manrope', sans-serif;
  margin: 20vh auto;
  text-align: center;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  
  @media (max-width: $tablet-width) {
    margin: 40px auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.info-block_title {
  font-weight: 800;
  font-size: 40px;
  line-height: 60px;
}

.info-block_text {
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
}
</style>
