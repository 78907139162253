export const low_rating_form =
  {
    tax_regime: {
      caption: "Режим налогообложения",
      name: "tax_regime",
      required: true,
      type: 'select',
      items: [
        { name: 'Общая система налогообложения', value: 'ОСНО' },
        { name: 'Упрощённая система налогообложения', value: 'УСН' },
        { name: 'Единый сельскохозяйственный налог', value: 'ЕСХН' },
        { name: 'Патентная система налогообложения', value: 'ПСН' }
      ],
      rules: [
        v => !!v || 'Укажите режим'
      ]
    },
  
    revenue_four_months: {
      caption: "Выручка за последний истекший квартал, руб",
      name: "revenue_four_months",
      required: true,
      type: 'number',
      rules: [
        v => !!v || 'Укажите выручку',
        value => {
          const pattern = /`/;
          return !pattern.test(value) || 'Символ апострофа запрещён'
        },
      ],
    },
  
    revenue_first_january: {
      caption: "Выручка с 01.01.2022 г, руб",
      name: "revenue_first_january",
      required: true,
      type: 'number',
      rules: [
        v => !!v || 'Укажите выручку',
        value => {
          const pattern = /`/;
          return !pattern.test(value) || 'Символ апострофа запрещён'
        },
      ],
    },
  
    tax_four_months: {
      caption: "Сумма уплаченных в последнем квартале налогов, руб",
      name: "tax_four_months",
      required: true,
      type: 'number',
      rules: [
        v => !!v || 'Укажите сумму',
        value => {
          const pattern = /`/;
          return !pattern.test(value) || 'Символ апострофа запрещён'
        },
      ],
    },
    
    tax_first_january: {
      caption: "Сумма налогов, уплаченных с 01.01.2022 г, руб",
      name: "tax_first_january",
      required: true,
      type: 'number',
      rules: [
        v => !!v || 'Укажите сумму',
        value => {
          const pattern = /`/;
          return !pattern.test(value) || 'Символ апострофа запрещён'
        },
      ],
    },
  
    avg_employees_four_months: {
      caption: "Среднесписочная численность сотрудников за последний истекший квартал",
      name: "avg_employees_four_months",
      required: true,
      type: 'number',
      rules: [
        v => !!v || 'Укажите численность',
        value => {
          const pattern = /`/;
          return !pattern.test(value) || 'Символ апострофа запрещён'
        },
      ],
    },
  
    number_of_employees: {
      caption: "Численность сотрудников на текущий момент",
      name: "number_of_employees",
      required: true,
      type: 'number',
      rules: [
        v => !!v || 'Укажите численность',
        value => {
          const pattern = /`/;
          return !pattern.test(value) || 'Символ апострофа запрещён'
        },
      ],
    },
  
    cost_of_fixed_assets: {
      caption: "Стоимость основных средств на текущий момент (за вычетом амортизации), руб",
      name: "cost_of_fixed_assets",
      required: true,
      type: 'number',
      rules: [
        v => !!v || 'Укажите стоимость',
        value => {
          const pattern = /`/;
          return !pattern.test(value) || 'Символ апострофа запрещён'
        },
      ],
    },
  
    is_company_group: {
      caption: "Относится ли компания к группе компаний",
      name: "is_company_group",
      required: true,
      type: 'select',
      items: [
        { name: 'Да', value: true },
        { name: 'Нет', value: false }
      ]
    },
  
    special_regime_document: {
      label: "Документ, подтверждающий использование спец. режима (если используется).",
      caption: "Загрузить файл",
      name: "special_regime_document",
      required: true,
      tax_regime: ['УСН', 'ЕСХН'],
      rules: [
        v => !!v[0] || 'file is required',
        v => (v[0] && v[0].size > 0) || 'file is required',
      ],
      type: 'file',
      multiple: true,
    },
  
    income_tax_declaration: {
      label: "Декларация по налогу на прибыль за последний отчетный период и год.",
      caption: "Загрузить файл",
      name: "income_tax_declaration",
      required: true,
      tax_regime: ['ЕСХН', 'ОСНО'],
      type: 'file',
      multiple: true,
      rules: [
        v => !!v[0] || 'file is required',
        v => (v[0] && v[0].size > 0) || 'file is required',
      ],
    },
    
    income_tax_declaration_eshn: {
      label: "Декларация по налогу, уплачиваемому при применении УСН",
      caption: "Загрузить файл",
      name: "income_tax_declaration",
      required: true,
      tax_regime: ['УСН'],
      type: 'file',
      multiple: true,
      rules: [
        v => !!v[0] || 'file is required',
        v => (v[0] && v[0].size > 0) || 'file is required',
      ],
    },
  
    vat_declaration3: {
      label: "Декларация по НДС за последние 3 истекших периода",
      caption: "Загрузить файл",
      name: "vat_declaration",
      required: true,
      tax_regime: ['ОСНО'],
      type: 'file',
      multiple: true,
      rules: [
        v => !!v[0] || 'file is required',
        v => (v[0] && v[0].size > 0) || 'file is required',
      ],
    },
    
    vat_declaration_osno: {
      label: "НД по налогу на прибыль",
      caption: "Загрузить файл",
      name: "vat_declaration",
      required: true,
      tax_regime: ['ОСНО'],
      type: 'file',
      multiple: true,
      rules: [
        v => !!v[0] || 'file is required',
        v => (v[0] && v[0].size > 0) || 'file is required',
      ],
    },
    
    vat_declaration_eshn: {
      label: "Декларация по налогу, уплачиваемому при применении ЕСХН за последний квартал",
      caption: "Загрузить файл",
      name: "vat_declaration",
      required: true,
      tax_regime: ['ЕСХН'],
      type: 'file',
      multiple: true,
      rules: [
        v => !!v[0] || 'file is required',
        v => (v[0] && v[0].size > 0) || 'file is required',
      ],
    },
  
    insurance_premiums_calculation: {
      caption: "Загрузить файл",
      label: "Отчет РСВ (расчет по страховым взносам) за последний истекший период",
      name: "insurance_premiums_calculation",
      required: true,
      tax_regime: ['УСН', 'ЕСХН', 'ОСНО'],
      type: 'file',
      multiple: true,
      rules: [
        v => !!v[0] || 'file is required',
        v => (v[0] && v[0].size > 0) || 'file is required',
      ],
    },
  
    // szvm_protocol_receiving: {
    //   label: "Протокол приема отчета СЗВ-М за последний истекший месяц.",
    //   caption: "Загрузить файл",
    //   name: "szvm_protocol_receiving",
    //   required: true,
    //   type: 'file',
    //   multiple: false,
    // },
  
    account_reporting: {
      label: "Бухгалтерская отчетность за истекший год (при наличии).",
      caption: "Загрузить файл",
      name: "account_reporting",
      required: false,
      tax_regime: ['УСН', 'ЕСХН', 'ОСНО'],
      type: 'file',
      multiple: true,
      rules: [
        v => !!v[0] || 'file is required',
        v => (v[0] && v[0].size > 0) || 'file is required',
      ],
    },
  
    assets_statement_accounting: {
      label: "Ведомость учета основных средств по состоянию на последний месяц.",
      caption: "Загрузить файл",
      name: "assets_statement_accounting",
      required: true,
      tax_regime: ['УСН', 'ЕСХН', 'ОСНО'],
      type: 'file',
      multiple: true,
      rules: [
        v => !!v[0] || 'file is required',
        v => (v[0] && v[0].size > 0) || 'file is required',
      ],
    },
    
    quarter_financial_statements: {
      label: "Промежуточная бухгалтерская отчетность по состоянию на конец истекшего квартала.",
      caption: "Загрузить файл",
      name: "quarter_financial_statements",
      required: true,
      tax_regime: ['УСН', 'ЕСХН', 'ОСНО'],
      type: 'file',
      multiple: true,
      rules: [
        v => !!v[0] || 'file is required',
        v => (v[0] && v[0].size > 0) || 'file is required',
      ],
    },
  
    staff_schedule: {
      label: "Штатное расписание.",
      caption: "Загрузить файл",
      name: "staff_schedule",
      required: true,
      tax_regime: ['УСН', 'ЕСХН', 'ОСНО'],
      type: 'file',
      multiple: true,
      rules: [
        v => !!v[0] || 'file is required',
        v => (v[0] && v[0].size > 0) || 'file is required',
      ],
    },
  }
