<script>
export default {
  name: 'ManagerCard',
  props: {
    manager: {
      type: [Array, Object]
    }
  },
  
  data () {
    return {
      showPhoto: false,
    }
  },
  
  methods: {
    getImg (path) {
      let result
      try {
        result = require(`../../../../public/manager_photo/${path}`)
      } catch (e) {
        if (e.code !== 'MODULE_NOT_FOUND') {
          throw e
        }
        result = require('../../../assets/img/user.jpg')
      }
      return result
    }
  }
}
</script>

<template>
  <div class="manager-card">
    <div class="manager-card__img" @click="showPhoto = true">
      <img :src="getImg(manager.photo)"/>
    </div>
    
    <div class="manager-card__info">
      <div class="manager-card__info-name">
        {{manager.full_name}}
      </div>
      
      <div class="manager-card__info-position">
        {{manager.position_name}}
      </div>
      
      <div class="manager-card__info-contact" v-if="manager.email">
        <a :href="'mailto:' + manager.email">
          {{manager.email}}
        </a>
      </div>
      
      <div
        class="manager-card__info-contact"
        v-if="manager.department_id != 9 && manager.department_id != 8"
      >
        <a :href="'tel:' + manager.phone_number">
          {{manager.phone_number}}
        </a>
      </div>
    </div>
    
    <v-dialog
      v-model="showPhoto"
      :scrollable="false"
      width="30%"
      he
    >
      <div class="manager-card__dialog-img">
        <img :src="getImg(manager.photo)"/>
      </div>
    </v-dialog>
  </div>
</template>


<style scoped lang="scss">
.manager-card {
  display: flex;
  flex-direction: row;
  
  &__img {
    width: 90px;
    height: 90px;
    flex-shrink: 0;
    border-radius: 50%;
    margin-right: 13px;
    cursor: zoom-in;
    
    > img {
      width: 90px;
      height: 90px;
      flex-shrink: 0;
      border-radius: 50%;
      object-fit: cover;
      object-position: 0 0;
    }
  }
  
  &__info {
    
    &-name {
      color: #000;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0.15px;
    }
    
    &-position {
      color: #000;
      text-align: center;
      width: auto;
      display: inline;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0.12px;
      border-radius: 9px;
      padding: 2px 15px;
      background: rgba(242, 164, 53, 0.60);
      text-transform: lowercase;
    }
    
    &-contact {
      font-size: 14px;
      font-weight: 600;
      
      > a {
        margin: 4px 0;
        text-decoration: none;
        color: var(--bg-dark) !important;
        transition-duration: 0.3s;
        
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  
  &__dialog-img {
    width: 100%;
    > img {
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: 0 0;
    }
  }
}

</style>
