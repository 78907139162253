<template>
  <div class="company-info">
    <table class="table-odded">
      <tr><td>Наименование</td><td>{{info.companyname}}</td></tr>
      <tr><td>Полное наименование</td><td>{{info.companyfullname}}</td></tr>
      <tr><td>ИНН/КПП</td><td>{{info.inn + ' / ' + info.kpp}}</td></tr>
      <tr><td>ОГРН</td><td>{{info.ogrn}}</td></tr>
      <tr><td>ОКПО</td><td>{{info.okpo}}</td></tr>
      <tr><td>Дата регистрации</td><td>{{info.startdate}}</td></tr>
      <tr><td>Адрес</td><td>{{info.address}}</td></tr>
      <tr><td>{{info.position}}</td><td>{{info.name}}</td></tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "CompanyInfo",
  props: {
    info: {
      type: Object
    },
  },
}
</script>

<style scoped>
.company-info{
  width: 85%;
  margin: auto;

}
.table-odded{
  border-collapse: collapse
}
.table-odded tr{
  line-height: 30px;
}
.table-odded tr:nth-child(odd){
  background-color: #e5f0f7;
}
.table-odded td{
  margin-left: 0.4%;
}
</style>
