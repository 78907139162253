<template>
  <div class="services-tab-main">
      <div class="price-group-title">Основные услуги</div>
      <div class="price-group-items">
        <table class="rate-card-detail-table" v-if="service_data">
          <thead>
          <tr style="font-weight: bold; text-align: left">
            <td class="td-left">Услуга</td>
            <td class="td-center">Валюта</td>
            <td class="td-right">Курс</td>
            <td class="td-right">Стоимость, вал</td>
            <td class="td-right">Стоимость, &#8381;</td>
          </tr>
          </thead>
        <tr class="rate-card-detail-service" v-for="item in service_data" :key="item.id">
            <td v-if="item.is_main != 0" class="td-left">{{item.service_name}}</td>
            <td v-if="item.is_main != 0" class="td-center">{{item.cur_code}}</td>
            <td v-if="item.is_main != 0" class="td-right">{{(item.cbr_rate ==1 ) ? '' : item.cbr_rate}}</td>
            <td v-if="item.is_main != 0" class="td-right">{{getnumber(item.price)}}</td>
            <td v-if="item.is_main != 0" class="td-right">{{getnumber(item.cost)}}</td>
        </tr>
          <tr class="tr-total">
            <td colspan="4" class="td-right">Итого:</td>
            <td class="td-right">{{getnumber(is_main_cost)}}&nbsp;&#8381;</td>
          </tr>
        </table>
      </div>
      <div class="price-group-title">Дополнительные услуги</div>
      <div class="price-group-items">
        <table class="rate-card-detail-table" v-if="service_data">
          <thead>
          <tr style="font-weight: bold; text-align: left">
            <td class="td-left">Услуга</td>
            <td class="td-center">Валюта</td>
            <td class="td-right">Курс</td>
            <td class="td-right">Стоимость, вал</td>
            <td class="td-right">Стоимость, &#8381;</td>
          </tr>
          </thead>
          <tr class="rate-card-detail-service" v-for="item in service_data" :key="item.id">
            <td v-if="item.is_main == 0" class="td-left">{{item.service_name}}</td>
            <td v-if="item.is_main == 0" class="td-center">{{item.cur_code}}</td>
            <td v-if="item.is_main == 0" class="td-right">{{(item.cbr_rate ==1 ) ? '' : item.cbr_rate}}</td>
            <td v-if="item.is_main == 0" class="td-right">{{getnumber(item.price)}}</td>
            <td v-if="item.is_main == 0" class="td-right">{{getnumber(item.cost)}}</td>
          </tr>
        <tr class="tr-total">
          <td colspan="4" class="td-right">Итого:</td>
          <td class="td-right">{{getnumber(is_added_cost)}}&nbsp;&#8381;</td>
        </tr>
        </table>
      </div>
    <div class="price-results">
      Полная стоимость заявки: {{ getnumber(is_main_cost + is_added_cost) }}&nbsp;&#8381;
    </div>
    
    <div class="rate-valid" v-if="order.rate_limit_date != null">
     Ставка действительна до
      <span> {{ new Date(order.rate_limit_date).toLocaleDateString('ru', {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric'
    }) }}
     </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServicesTab",
  props: {
    order: {
      type: Object
    }
  },
  data: () => ({
    service_data: [],
    is_main_cost: 0,
    is_added_cost: 0,
  }),
  watch: {
    order: function () {
      this.getOrderCostById()
    },
    deep: true
  },
  mounted() {
    this.getOrderCostById()
  },
  methods: {
    getOrderCostById(){
      this.$postapi(this.$address + this.$getters.getFinDetailsByOrderId.uri, {
        method: this.$getters.getFinDetailsByOrderId.name,
        ord_id: this.order.ord_id
      }).then(data => {
        if (data.message != 0) {
          this.service_data = JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`))
          this.service_data.sort(function (a, b) {
            return a.id - b.id;
          });
          this.getServiceResults()
        }
      })
    },
    getServiceResults(){
      this.is_main_cost = 0
      this.is_added_cost = 0
      for (let i = 0; i < this.service_data.length; i++){
        if (this.service_data[i].is_main == 1){
          this.is_main_cost = parseFloat(this.is_main_cost) + parseFloat(this.service_data[i].cost)
        } else {
          this.is_added_cost = parseFloat(this.is_added_cost) + parseFloat(this.service_data[i].cost)
        }
      }
    },
    getnumber(value){
      return parseFloat(value).toLocaleString('ru-RU')
    },
  },
}
</script>

<style scoped lang="scss">
.services-tab-main{
  text-align: left;
}
.price-group-title{
  width: 98%;
  text-align: left;
  border-bottom: #6c8693 1px solid;
  margin: 1.61%;
  padding: 0 0 4px;
}
.price-group-items{
  padding-bottom: 3.22%;
}
.td-right{
  text-align: right;
  padding-right: 1px;
}
.td-left{
  text-align: left;
  padding-right: 1px;
}
.td-center{
  text-align: center;
}
.tr-total{
  background-color: #fff;
  font-weight: bold;
  font-size: 0.8vw;
}
.price-results{
  width: 98%;
  text-align: right;
  font-weight: bold;
  border-top: 1px solid #6c8693;
  margin: 1.61% 1.61% 15px 1.61%;
  padding: 0 0 4px;
}

.rate-valid {
  width: 98%;
  font-size: 16px;
  text-align: right;
  margin: 10px 0;
  
  > span {
    background: #eaeaea;
    padding: 0 6px;
    border-radius: 5px;
    margin-left: 3px;
  }
}
.rate-card-detail-service{
  line-height: 25px;
}
.rate-card-detail-table{
  width: 98%;
  margin: auto;
  font-size: 14px;
  padding-top: 1.61%;
}
</style>
