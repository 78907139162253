<script>
import CVRequestList from './request-list/CVRequestList.vue'

export default {
  name: 'App',
  
  components: {
    CVRequestList,
  },
  
  data: () => ({
  }),
};
</script>

<template >
  <div class="cabinet-template">
    <div class="cabinet">
      <CVRequestList class="cabinet__list"/>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../../../node_modules/devextreme/dist/css/dx.common.css';
@import '../../../node_modules/devextreme/dist/css/dx.material.orange.light.css';

.cabinet-template {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.cabinet {
  position: absolute;
  top: 80px;
  left: -0.5%;
  width: 101%;
  overflow: hidden !important; /* Hide scrollbars */
  
  @media (min-width: 800px) {
  width: 101%;
  }
}

.cabinet2 {
  background: #2ea700;
}

.mt-notify{
  margin-top: 0.4%;
  margin-right: 0.4%;
  border-radius: 0.02vw;
}
.mt-notify-text{
  font-size: 0.8vw;
}
.mt-notify-title{
  font-size: 1vw;
}
.mt-editor-form{
  background-color: #fff;
  overflow: hidden !important;
}
.v-dialog{
  background-color: #ffffff;
  width: 60% !important;
  border-radius: 10px !important;
}
.splitpanes.default-theme .splitpanes__pane{
  background-color: #fff !important;
}
.splitpanes__splitter::before{
  background-color: coral !important;
}
.splitpanes__splitter::after{
  background-color: coral !important;
}
.default-theme.splitpanes--vertical>.splitpanes__splitter, .default-theme .splitpanes--vertical>.splitpanes__splitter{
  border-left: 0px !important;
}
.v-list-item__title{
  text-align: left;
  font-size: 11pt !important;
}
a.dx-link.dx-link-delete::before{
  content: url('https://api.iconify.design/mdi/delete-forever.svg');
}
a.dx-link.dx-link-delete{
  font-size: 0pt;
}
//
//.online-chat-root-TalkMe #supportTrigger {
//  bottom: 30px !important;
//  right: 80px !important;
//  display: none !important;
//}
</style>
