<script>
import ManagerCard from "./ManagerCard";
export default {
  name: 'ManagerList',
  
  components: {
    ManagerCard
  },
  
  props: {
    managers: {
      type: [Object, Array]
    }
  },
  
  data () {
    return {
      manger_types: [
        'bm_card',
        'buhgalter_card',
        'car_manager_card_1',
        'car_manager_card_2',
        'car_manager_card_3',
        'main_manager',
        'manager_card',
      ],
      
      showFullList: false,
    }
  },
  
  computed: {
    getManagers() {
      if (this.showFullList == true) {
        return this.managers
      } else
        return this.managers.slice(0,1)
    }
  },
  
  methods: {
  }
}
</script>

<template>
  <div class="manager-list">
    
    <div class="manager-list__title">
      Контакты Vedexx
    </div>
    <transition-group name="card" tag="div">
      <div
        v-for="(manager, index) in getManagers"
        :key="index + 1"
      >
        <ManagerCard
          :manager="manager"
          class="manager-list__card"
        />
      </div>
    </transition-group>
    <div
      v-if="showFullList === false"
      class="manager-list__btn-show-more manager-list__btn"
      @click="showFullList = true"
    >
      Показать больше
    </div>
    
    <div
      v-else
      class="manager-list__btn-hide manager-list__btn"
      @click="showFullList = false"
    >
      Скрыть
    </div>
  
  </div>
</template>


<style scoped lang="scss">
.card-enter-active {
  transition: all 0.7s;
}

.card-leave-active {
  transition: all;
}

.card-enter, .card-leave-to /* .list-leave-active до версии 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}

.manager-list {
  
  &__title {
    color: #000;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.22px;
    margin: 20px 0 15px;
  }
  
  &__card {
    margin: 22px 0;
  }
  
  &__btn {
    width: 100%;
    margin-top: 20px;
    font-weight: 800;
    font-size: 16px;
    color: #000000;
    letter-spacing: 0.01em;
    transition-duration: 2s;
    cursor: pointer;
    line-height: 16px;
    
    &:hover {
      text-decoration: underline;
    }
    
    &-show-more {
      display: flex;
      
      &:after {
        content: url('https://api.iconify.design/iconamoon/arrow-down-2-duotone.svg?width=20&height=20');
        text-align: center;
        line-height: 10px;
      }
    }
    
    &-hide{
      display: flex;
      
      &:after {
        content: url('https://api.iconify.design/iconamoon/arrow-up-2-duotone.svg?width=20&height=20');
        text-align: center;
        line-height: 10px;
      }
    }
  }
}

</style>
