<script>
export default {
  props: {
    title: {
      type: String,
    },
  },
  
  data: () => ({
    valid: false,
  }),
  
  methods: {
    submitForm: function (e) {
      this.$emit("submitForm", this.valid);
      
      e.preventDefault();
    },
  },
};
</script>

<template>
  <v-form
    class="app-form"
    @submit.prevent="submitForm"
    ref="form"
    v-model="valid"
  >
    <h2 v-if="title" id="example" class="app-form__title">{{ title }}</h2>

    <div class="app-form__wrapper">
      <slot></slot>
    </div>
  </v-form>
</template>

<style lang="scss" scoped>
.app-form {
  padding: 32px;
  overflow-y: auto;
  background: white;
  overscroll-behavior-y: contain;
  position: relative;
  min-width: 500px;
  box-shadow: 0vw 0.15625vw 0.05208vw -0.1041666vw rgb(65, 64, 64),
  0vw 0.1041666vw 0.1041666vw 0vw rgba(96, 97, 99, 0.14),
  0vw 0.05208vw 5px 0vw rgba(96, 97, 99, 0.12);
  border-radius: 5px;

  @media (max-width: 476px) {
    width: 100%;
  }

  &__title {
    margin-bottom: 8px;
    text-align: center;
  }

  &__wrapper {
    margin-top: 32px;
  }
}
</style>
