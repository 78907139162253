<script>
import CVTBDateInput from "../../common/CVTBDateInput";
import {bus} from "../../../router/bus";

export default {
  name: "AnalysisRateForm",
  components: {
    CVTBDateInput,
  },
  
  data: () => ({
    valid: false,
    
    rules: {
      sender_required: value => !!value || 'Поле обязательно для заполнения',
      sender_requiredArray: value => value.length > 0 || 'Поле обязательно для заполнения',
      sender_match: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Введенный адрес некорректен...'
      },
    },
    
    elements: {
      date_from: {
        caption: "Дата начала анализа",
        name: "date_from",
        disabled: false,
        type: "date",
        rules: [
          v => !!v || 'Укажите дату',
        ]
      },
      
      date_to: {
        caption: "Дата окончания анализа ",
        name: "date_to",
        disabled: false,
        type: "date",
        rules: [
          v => !!v || 'Укажите дату',
        ]
      },
    },
  
    route_params: [
      {
        id: 1,
        name: "Через Дальний Восток"
      },
      {
        id: 2,
        name: "Через Новороссийск"
      },
      {
        id: 3,
        name: "Через Санкт-Петербург"
      },
      {
        id: 4,
        name: "Прямое ЖД"
      },
    ],
    
    places_from: [],
    places_to: [],
    unit_codes: [],
    linelist: [],
    
    searchPlaceTo: null,
    searchPlaceFrom: null,
    
    formdata: {
      date_from: null,
      date_to: null,
      place_from: [],
      place_to: [],
      agent: [],
      unit_code: [],
      route: [],
      email: null
    },
  }),
  
  mounted() {
    this.getReferenceData('lgst_place_from_wg')
    this.getReferenceData('lgst_place_to_wg')
    this.getReferenceData('lgst_line_list')
    this.getReferenceData('lgst_cont_types')
    this.getDateFrom()
  },
  
  methods: {
    getReferenceData(referenceName) {
      this.$postapi(this.$address + this.$getters.getReferenceData.uri, {
        method: this.$getters.getReferenceData.name,
        reference_name: referenceName
      }).then(data => {
        if (data.error == 0) {
          switch (referenceName) {
            case 'lgst_place_from_wg':
              this.places_from = JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`));
              break
            case 'lgst_place_to_wg':
              this.places_to = JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`));
              break
            case 'lgst_line_list':
              this.linelist = JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`))
              this.sortLines()
              break
            case 'lgst_cont_types':
              this.unit_codes = JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`));
              break
          }
        }
      });
    },
    
    sortLines() {
      let index = this.linelist.findIndex(el => el.id == 0)
      this.linelist.splice(index,1)
    },
    
    getDateFrom() {
      var currentDate = new Date();
      
      currentDate.setMonth(currentDate.getMonth() - 2);
      this.formdata.date_from = currentDate.toISOString().substr(0, 10);
      
      currentDate = new Date(); // Сбрасываем дату на текущую
      this.formdata.date_to = currentDate.toISOString().substr(0, 10);
    },
    
    allowedDatesFrom(val) {
      var currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() - 2);
      return val <= currentDate.toISOString().substr(0, 10);
    },
    
    allowedDatesTo(val) {
      var minDate = new Date(this.formdata.date_from);
      minDate.setMonth(minDate.getMonth() + 2);
      
      return val >= minDate.toISOString().substr(0, 10);
    },
    
    sendAnalysisRequest() {
      this.$postapi(this.$address + this.$getters.setRateAnalizeRequest.uri, {
        method: this.$getters.setRateAnalizeRequest.name,
        date_from: this.formdata.date_from,
        date_to: this.formdata.date_to,
        place_from: this.formdata.place_from,
        place_to: this.formdata.place_to,
        agent: this.formdata.agent,
        unit_code: this.formdata.unit_code,
        route_caption: this.formdata.route,
        email: this.formdata.email,
        token: this.$_getsetting('token'),
      }).then(data => {
        if (data.error == 0) {
          bus.$emit("show_notify", {
            color: "success",
            notifytext: 'Ваш запрос сформирован и будет обработан в течение 3-х рабочих дней!!'
          });
          this.resetForm()
        }
      })
    },
    
    resetForm() {
      this.$refs.form.reset()
    },
    
    validate() {
      this.$refs.form.validate()
      if (this.valid === true) {
        this.sendAnalysisRequest()
      }
    }
  }
}
</script>

<template>
  <v-form
    class="analysis-form"
    ref="form"
    v-model="valid"
    @submit.prevent="validate"
  >
    <div class="analysis-form__ttl font-weight-bold">Запрос анализа ставок за прошедший период</div>

    <v-alert
      text
      dense
      class="my-4"
      color="var(--main-bg-color)"
      icon="mdi-information-slab-box"
      border="left"
      width="70%"
    >
      Здесь вы можете заказать анализ ставок за прошедшие периоды.
      <br>
      Данный функционал не предназначен для запроса ставок, действующих в текущем месяце.
    </v-alert>
    
    <div class="analysis-form__container">
      <CVTBDateInput
        @selected="formdata.date_from = $event.value"
        @input="formdata.date_from = $event"
        :element="elements.date_from"
        :val="formdata.date_from"
        :allowed-dates="allowedDatesFrom"
      />
      
      <CVTBDateInput
        @selected="formdata.date_to = $event.value"
        @input="formdata.date_to = $event"
        :element="elements.date_to"
        :val="formdata.date_to"
        :allowed-dates="allowedDatesTo"
      />
      
      <v-autocomplete
        v-model="formdata.place_from"
        :rules="[rules.sender_requiredArray]"
        color="var(--main-orange)"
        item-value="id"
        chips
        clearable
        deletable-chips
        outlined
        multiple
        small-chips
        required
        label="Порт отправки"
        item-text="name"
        :items="places_from"
        item-color="var(--main-bg-color)"
        :search-input.sync="searchPlaceFrom"
        @input="searchPlaceFrom = ''"
      >
      </v-autocomplete>
      
      <v-autocomplete
        v-model="formdata.place_to"
        :items="places_to"
        :rules="[rules.sender_requiredArray]"
        color="var(--main-orange)"
        chips
        clearable
        deletable-chips
        outlined
        multiple
        required
        small-chips
        label="Место прибытия"
        item-text="name"
        item-value="id"
        item-color="var(--main-bg-color)"
        :search-input.sync="searchPlaceTo"
        @input="searchPlaceTo = ''"
      >
      </v-autocomplete>
      
      <v-autocomplete
        v-model="formdata.agent"
        :items="linelist"
        :rules="[rules.sender_requiredArray]"
        chips
        clearable
        deletable-chips
        outlined
        multiple
        small-chips
        required
        color="var(--main-orange)"
        item-text="name"
        label="Подрядчик (линия/агент)"
        item-value="id"
        item-color="var(--main-bg-color)"
      />
      
      <v-autocomplete
        v-model="formdata.unit_code"
        :items="unit_codes"
        :rules="[rules.sender_requiredArray]"
        chips
        clearable
        outlined
        deletable-chips
        multiple
        small-chips
        required
        color="var(--main-orange)"
        item-text="name"
        label="Тип оборудования"
        item-value="id"
        item-color="var(--main-bg-color)"
      />
      
      <v-autocomplete
        v-model="formdata.route"
        :items="route_params"
        :rules="[rules.sender_requiredArray]"
        chips
        clearable
        outlined
        deletable-chips
        multiple
        small-chips
        required
        color="var(--main-orange)"
        item-text="name"
        label="Маршрут"
        item-value="name"
        item-color="var(--main-bg-color)"
      />
      
      <v-text-field
        outlined
        required
        type="text"
        :rules="[rules.sender_required, rules.sender_match]"
        label="Email"
        v-model="formdata.email"
        color="var(--main-orange)"
      />
    </div>
    
    <v-btn
      rounded
      outlined
      type="submit"
      color="var(--main-bg-color)"
    >
      Отправить
    </v-btn>
  </v-form>
</template>

<style lang="scss" scoped>
.analysis-form {
  padding: 10px 20px;
  
  &__ttl {
    margin: 0 0 10px;
    font-size: 22px;
    line-height: 24px;
    color: #191919;
  }
  
  &__subttl {
    max-width: 80%;
    line-height: 24px;
    color: #191919;
    font-weight: 300;
  }
  
  &__container {
    width: 70%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    margin: 20px 0 0;
  }
}
</style>
