<script>
import FtpUploader from "../../common/FtpUploader";
import DocRequestForm from "../forms/DocRequestForm";
import CompanyContractCard from "./CompanyContractCard";
import CompanyRequisite from "./CompanyRequisite"

export default {
  name: "CompanyInfoCard",
  
  components: {
    FtpUploader,
    DocRequestForm,
    CompanyContractCard,
    CompanyRequisite
  },
  
  props: {
    payersData: {
      type: [Array, Object]
    },
    
    profileData: {
      type: [Array, Object]
    },
    
    mobileVersion: {
      type: Boolean,
      default: false
    },
  },
  
  data() {
    return {
      showExpandInfo: this.mobileVersion === true ?  false : true,
      showFileForm: false,
      showStatus: false,
      showDocRequestForm: false,
      
      indexOfContract: 0,
      idOfContract: null,
      file_name: [],
      
      temp_id: Math.floor(Math.random() * 1e8),
      
      order_file: {
        caption: "Документ компании",
        name: "order_file",
        multiple: true,
        disabled: true,
        alt: "Добавьте файл компании",
      },
    }
  },
  
  watch: {
    payersData: {
      handler: function() {
        this.resetContractIndex()
      },
      deep: true
    }
  },
  
  mounted() {
   this.resetContractIndex()
  },
  
  methods: {
    setFilesName(data) {
      this.showStatus = false
      this.file_name.push(data)
    },
  
    fileUploaded() {
      this.$postapi(this.$address + this.$setters.addContractFile.uri, {
        method: this.$setters.addContractFile.name,
        temp_id: this.temp_id,
        file_name: this.file_name,
        company_id: this.payersData.id,
        ord_id: this.payersData.contract_data[this.indexOfContract].id,
      }).then(data => {
        if (data.error == 0){
          this.showStatus = true
          setTimeout(() => {
            this.showFileForm = false
            this.showStatus = false
            this.file_name = []
          }, 1700);
        }
      });
    },
  
    openLowRatingForm() {
      var queryParams = {
        cid: this.payersData.id
      }
      let routeData = this.$router.resolve({
          path: "/LowRatingForm",
          query: queryParams
        });
        window.open(routeData.href, '_blank');
    },
    
    resetContractIndex() {
      this.indexOfContract = 0
      this.idOfContract = this.payersData.contract_data[0].id
    },
    
    updateContractIndex() {
      this.indexOfContract = this.payersData.contract_data.findIndex(el => el.id === this.idOfContract);
    },
  }
}
</script>

<template>
  <div>
    <!--    <v-card-->
    <!--      elevation="2"-->
    <!--      class="profile_card profile__card-info"-->
    <!--    >-->
    <!--      <v-card-text class="row justify-space-between">-->
    <!--        <div class="text&#45;&#45;black profile__info-block">-->
    <!--          <h1 class="profile__info-ttl"> {{ payersData.name}}</h1>-->
    <!--        -->
    <!--          <h4 class="profile__info">-->
    <!--            {{profileData.email}}-->
    <!--          </h4>-->
    <!--          -->
    <!--          <h4 class="profile__info">-->
    <!--           {{profileData.login}}-->
    <!--          </h4>-->
    <!--        </div>-->
    <!--      </v-card-text>-->
    <!--      -->
    <!--      <v-btn-->
    <!--        class="profile__card-info__expand-btn"-->
    <!--        icon-->
    <!--        color="black"-->
    <!--        @click="showExpandInfo = !showExpandInfo"-->
    <!--      >-->
    <!--        <v-icon>-->
    <!--          {{showExpandInfo === true ? 'mdi-chevron-up' : 'mdi-chevron-down'}}-->
    <!--        </v-icon>-->
    <!--      </v-btn>-->
    <!--  -->
    <!--    </v-card>-->
    
    <div class="profile__container">
      <v-card
        v-if="showExpandInfo === true"
        elevation="3"
        class="profile_card profile__card-payers"
      >
        
        <div class="text--black">
          
          <div class="profile__card-payers__ttl">
            <h1 class="profile__info-ttl">Реквизиты компании</h1>
          </div>
          
          <div class="profile__card-payers__data">
            
            <CompanyRequisite :isCompanyInfo="true" :payersData="payersData"/>
            
            <div class="profile__card-payers__data-progress" v-if="payersData.company_rate != 0 && payersData.company_rate">
              <v-progress-circular
                :size="60"
                width="4"
                :value="payersData.company_rate*10"
                :color="payersData.company_rate > 4 ? 'var(--green-dark)' : 'var(--error-dark)'"
              >
                {{ payersData.company_rate }}/10
              </v-progress-circular>
              <p>
                рейтинг <br/>организации
              </p>
              <div
                class="profile__card-payers__data-progress-link"
                @click="openLowRatingForm"
                to="/LowRatingForm"
                v-if="payersData.company_rate < 6"
              >
                <p>заполнить</p> <p>форму</p>
              </div>
              <p v-if="payersData.company_rate < 6">
                *требуется дополнительный анализ для продолжения работы с клиентом!
              </p>
            </div>
          </div>
        </div>
      </v-card>
      
      <v-card
        v-if="showExpandInfo === true && payersData.contract_data[0] != null"
        elevation="3"
        class="profile_card profile__card-payers"
      >
        <div class="profile__card-payers__ttl">
          <h1 class="profile__info-ttl">Договоры</h1>
          <v-select
            class="profile__card-payers__ttl__select"
            v-model="idOfContract"
            color="var(--bg-dark)"
            dense
            solo
            flat
            item-value="id"
            item-text="name"
            :items="payersData.contract_data"
            item-color="var(--main-orange)"
            @change="updateContractIndex"
          />
          
          <v-btn
            class="profile__card-payers__ask-btn"
            depressed
            text
            @click="showDocRequestForm = true"
          >
            запросить акт сверки
          </v-btn>
        </div>
        
        <div class="profile__card-payers__container">
          
          <h3>№ {{payersData.contract_data[indexOfContract].doc_number}}</h3>
          <CompanyContractCard @showFileForm="showFileForm = true" :contract="payersData.contract_data[indexOfContract]"/>
        </div>
      </v-card>
    </div>
    
    <v-dialog
      v-if="payersData.contract_data[0] != null"
      v-model="showDocRequestForm"
      persistent
      width="600"
    >
      <DocRequestForm
        @close="showDocRequestForm = false"
        :contractId="payersData.contract_data[indexOfContract].id"
      />
    </v-dialog>
    
    <v-dialog
      width="40%"
      height="60%"
      v-model="showFileForm"
      v-if="showFileForm"
    >
      <v-card class="py-10 px-6">
        <div class="text-h5 px-8 mt-3 row justify-space-between">
          Выберите документ организации
          <v-btn
            icon
            rounded
            @click="showFileForm = false, showStatus = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-form class="profile__add-file">
          <FtpUploader
            @fileUploaded="setFilesName"
            :element="order_file"
            :order_key="temp_id"
          />
          
          <span
            v-if="showStatus && showFileForm && file_name.length > 1"
            class="mt-2"
          >Файлы загружены
          </span>
          
          <span
            v-if="showStatus && showFileForm && file_name.length == 1"
            class="mt-2"
          > Файл загружен
          </span>
        </v-form>
        
        <v-card-actions class="mt-2 mb-0">
          <v-spacer></v-spacer>
          <v-btn
            color="var(--main-bg-color)"
            text
            @click="fileUploaded"
          >
            Сохранить
          </v-btn>
          <v-btn
            color="var(--main-bg-color)"
            text
            @click="showFileForm = false"
          >
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped lang="scss">
.profile {
  
  &__info-block {
    margin: 8px;
  }
  
  &__rate {
    margin-top: 10px;
  }
  
  &__card-info {
    width: 25%;
    height: 10%;
    align-content: center;
    padding: 10px;
    justify-content: center;
    
    @media (max-width: 700px) {
      width: 97%;
    }
    
    &__expand-btn {
      display: flex;
      margin-top: 5%;
      align-content: center;
      justify-content: center;
      
      @media (min-width: 1000px) {
        display: none;
      }
    }
  }
  
  &__container {
    //width: 55%;
  }
  
  &__card-payers {
    width: 100%;
    height: auto;
    
    @media (max-width: 700px) {
      width: 97%;
    }
    
    &__ttl {
      border-bottom: 1px solid rgba(180, 180, 180, 0.73);
      width: 100%;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      padding: 10px 10px;
      
      > h1 {
        margin: 10px 15px;
      }
      
      &__select {
        position: absolute;
        top: 12px;
        left: 150px;
        width: 250px;
        font-variant: all-small-caps;
        font-weight: 800;
        letter-spacing: 0.02em;
        font-size: 20px;
        color: var(--bg-dark) !important;
      }
    }
    
    &__ask-btn {
      font-variant: all-small-caps;
      font-weight: 800;
      letter-spacing: 0.02em;
      font-size: 16px;
      color: var(--bg-dark);
    }
    
    &__data {
      margin: 25px 25px 0;
      padding-bottom: 15px;
      
      &-progress {
        position: absolute;
        top: 85px;
        right: 20px;
        text-align: right;
        font-weight: 600;
        float: right;
        max-width: 150px;
        
        > p {
          margin-top: 5px;
          margin-bottom: 4px;
          line-height: 18px;
          font-weight: 300;
          font-size: 13px;
        }
        
        &-link {
          margin-top: 5px;
          margin-bottom: 10px;
          color: var(--bg-dark);
          text-decoration: none;
          line-height: 10px;
          cursor: pointer;
  
          > p {
            line-height: 10px;
            margin-bottom: 8px;
          }
  
          &:hover {
            text-decoration: underline;
          }
  
          &:active,
          &:focus {
            color: var(--main-bg-color);
          }
        }
        
        > a {
        }
      }
    }
    
    &__container {
      padding: 10px 20px 30px;
      display: grid;
      
      > h3 {
        margin: 0 5px 5px;
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        letter-spacing: 0.01em;
      }
    }
  }
  
  &__info-ttl {
    margin: 0 0 0;
    color: #191919;
    font-size: 22px;
    line-height: 24px;
    
    @media (max-width: 700px) {
      line-height: 22px;
      font-size: 18px;
      margin: 0 0 5px;
      letter-spacing: 0.01em;
      font-weight: 600;
      color: #000000;
    }
  }
  
  &__info {
    margin: 5px 0;
    color: #191919;
    font-size: 16px;
    
    @media (max-width: 700px) {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      margin: 2px;
      letter-spacing: 0.01em;
      color: #000000;
      
    }
  }
  
  &__add-file {
    position: relative;
    background: white;
    margin: 20px 30px ;
  }
}

.profile_card {
  margin: 0 15px 10px 5px;
  border-radius: 8px;
  height: auto;
  
  @media (max-width: 700px) {
    margin: -4px 20px 0 5px;
  }
}

</style>
